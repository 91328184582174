/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import startsWith from 'lodash/startsWith';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import BlockContainer from '../../components/BlockContainer';
import CdnImage, { cdnImagePropTypes } from '../../components/CdnImage';
import { isVideo } from '../../components/cms/utils';
import { eventTags } from '../../core/trackers/events';
import OptLink from '../../OptLink';
import { imageProptypes } from '../../propTypes';
import { videoCDN } from '../../utils/videoUtils';
import './Banner.scss';

function isVideoImage(src) {
  if (!src) return false;
  if (startsWith(src.mimeType, 'video/')) return true;
  return isVideo(src.filename);
}

const ImageOrVideo = ({ src, ...props }) => {
  if (isVideoImage(src)) {
    const { onClick, style } = props;
    return (
      <video
        playsInline
        disableRemotePlayback
        disablePictureInPicture
        x-webkit-airplay="deny"
        autoPlay
        muted
        loop
        controls={false}
        onClick={onClick}
        style={style}
      >
        <source src={videoCDN(src.uri)} type={src.mimeType || 'video/mp4'} />
        Your browser does not support the video tag.
      </video>
    );
  }
  return <CdnImage src={src} {...props} />;
};

export const ClickableImage = ({ entity, image, link, trackClick, openMode, ...props }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (openMode === 'modal') {
      setOpen(true);
    }
  };

  return (
    <OptLink entity={entity} to={link} trackClick={trackClick}>
      <ImageOrVideo
        src={image}
        {...props}
        onClick={handleOpen}
        srcSet="auto"
        style={{ cursor: openMode === 'modal' ? 'pointer' : undefined }}
      />
      {open && openMode === 'modal' && !link && (
        <Modal
          open
          basic
          size="fullscreen"
          onClose={() => setOpen(false)}
          closeOnDocumentClick
          style={{ marginLeft: '2.5%' }}
        >
          <Modal.Content onClick={() => setOpen(false)}>
            <CdnImage src={image} {...props} srcSet maxWidth={1920} />
          </Modal.Content>
        </Modal>
      )}
    </OptLink>
  );
};

const OptionalHeader = ({ tag: Tag, hasAltText, children }) => {
  if (hasAltText) {
    return <Tag>{children}</Tag>;
  }
  return <>{children}</>;
};

function Banner(props) {
  const {
    banner,
    image,
    link,
    seo,
    trackClick,
    maxWidth,
    maxHeight,
    func,
    container,
    openMode,
  } = props;

  if (!banner && !image) return null;
  const titleLevel = seo?.as || 'h1';
  return (
    <BlockContainer className="block--banner Home--banner" type="segment-light" {...container}>
      <OptionalHeader tag={titleLevel} hasAltText={!!seo?.text}>
        <ClickableImage
          link={link}
          trackClick={trackClick}
          image={image || banner}
          alt={seo?.text || image?.altText || banner?.altText || ''}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          func={func}
          fluid
          srcSet
          openMode={openMode}
        />
      </OptionalHeader>
    </BlockContainer>
  );
}

Banner.defaultProps = {
  banner: undefined,
  container: {},
  image: undefined,
  func: undefined,
  link: undefined,
  maxHeight: undefined,
  maxWidth: 1127,
  openMode: undefined,
  seo: undefined,
  trackClick: eventTags.CTA_GO_TO,
};

Banner.propTypes = {
  banner: imageProptypes,
  container: PropTypes.object,
  image: imageProptypes,
  func: cdnImagePropTypes.func,
  link: PropTypes.string,
  openMode: PropTypes.string,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  trackClick: PropTypes.string,
  seo: PropTypes.shape,
};

export default Banner;
