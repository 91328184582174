import { useMount } from 'ahooks';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';
import { CmsPageLayout } from '../../cms/CmsPage';
import { CmsBlocks } from '../../cms/CmsScreen';
import ItemCardGroup from '../../components/ItemCardGroup';
import ScreenIntro from '../../components/screens/ScreenIntro';
import SponsorCard from '../../components/sponsors/SponsorCard';
import { bem } from '../../core/design/bem';
import MenuFilter from '../../core/filter/MenuFilter';
import { generateFilters } from '../../core/filter/utils';
import { cmsBlocksType, orderByType } from '../../core/platformPropTypes';
import { selectUsers } from '../../core/users/users.select';
import { fetchUserCollections } from '../../core/users/users.utils';
import { throttleDispatchFetch } from '../../utils';
import { useFilter, useOrderBy } from '../../utils/hooks';

const pageBlock = bem('page');

const throttledFetch = throttleDispatchFetch(fetchUserCollections);

const defaultOrder = [
  { field: 'order', order: 'asc' },
  { field: 'name', order: 'asc' },
];

const ExhibitorsPage = (props) => {
  const {
    collection,
    filters: fieldsToFilter = [],
    filterConfig,
    groupBy,
    orderBy: orderByConfig = defaultOrder,
    itemProps,
    header,
    footer,
    pageId,
    pageHeader,
    pageFooter,
    metadata,
    design,
    match,
    ...rest
  } = props;
  const category = match?.params?.category;
  const { users = [] /* , loaded */ } = useSelector((state) => selectUsers(state, [collection]));
  const [filteredData, currentFilters, setFilters] = useFilter(
    users,
    fieldsToFilter,
    omitBy({ category }, isUndefined),
  );
  const finalSponsors = useOrderBy(filteredData, orderByConfig);

  console.log("Sponsors", filteredData, finalSponsors, users, fieldsToFilter);
  useMount(() => throttledFetch([collection]));
  return (
    <CmsPageLayout
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      metadata={metadata}
      designOverride={design}
      className={pageBlock({ exhibitors: true, [pageId]: true, category }).toString()}
    >
      {header && <CmsBlocks blocks={header.blocks} />}
      <Container>
        <Grid stackable columns="equal">
          {fieldsToFilter && fieldsToFilter.length > 0 && (
            <Grid.Column width={4} className="sidebar--left">
              <div style={{ paddingBottom: 10 }}>
                <MenuFilter
                  filterList={generateFilters(users, fieldsToFilter, currentFilters)}
                  filters={currentFilters}
                  onChange={setFilters}
                  filterConfig={filterConfig}
                  pageId={pageId}
                />
              </div>
            </Grid.Column>
          )}
          <Grid.Column>
            <ScreenIntro type="exhibitors" category={collection} />
            <ItemCardGroup
              {...rest}
              groupClassName="sponsors"
              items={finalSponsors}
              groupBy={groupBy}
              component={(p) => <SponsorCard {...itemProps} {...p} />}
            />
          </Grid.Column>
        </Grid>
      </Container>
      {footer && <CmsBlocks blocks={footer.blocks} />}
    </CmsPageLayout>
  );
};

ExhibitorsPage.defaultProps = {
  collection: 'exhibitors',
  design: {},
  filterConfig: undefined,
  filters: [],
  footer: undefined,
  groupBy: undefined,
  header: undefined,
  orderBy: undefined,
  pageFooter: undefined,
  pageHeader: undefined,
  metadata: undefined,
};

ExhibitorsPage.propTypes = {
  collection: PropTypes.string,
  design: PropTypes.object,
  filterConfig: PropTypes.object,
  filters: PropTypes.array,
  footer: cmsBlocksType,
  groupBy: PropTypes.object,
  header: cmsBlocksType,
  orderBy: orderByType,
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string,
    }).isRequired,
  }).isRequired,
  pageId: PropTypes.string.isRequired,
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  metadata: PropTypes.object,
};

export default ExhibitorsPage;
