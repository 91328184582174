import PropTypes from 'prop-types';
import React from 'react';
import { Card, Label } from 'semantic-ui-react';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import { getEntityString } from '../../utils';
import CdnImage from '../CdnImage';
import EntityLink from '../EntityLink';
import './SponsorCard.scss';

export function getSponsorString(sponsor, key) {
  return getEntityString(sponsor, 'sponsor', key);
}

const defaultTags = [{
  field: 'category',
  icon: 'trophy'
}]

const SponsorTag = (props) => {
  const { tag = {}, item } = props;
  const {icon = 'trophy'} = tag;
  const content = getSponsorString(item, tag.field);

  if (!content) {
    return null;
  }

  return (
    <Label
      icon={icon}
      className={`${icon}--${content}`}
      content={content}
    />
  );
}

const SponsorCard = ({ item, tagFields }) => {
  console.log("jkhsdhsidu", tagFields);
  const { trackEvent } = useTracking();
  const { _id, collection, shortDescription = '', logo, name } = item;

  const tags = tagFields?.length? tagFields: defaultTags;

  return (
    <Card
      className={`card--sponsor ${collection}--${_id}`}
      as={EntityLink}
      entity={item}
      onClick={() => trackEvent(eventTags.SPONSOR_GO_TO_PAGE, { item })}
    >
      {logo && (
        <Card.Content style={{ flexGrow: 0 }} className="logo">
          <CdnImage as="img" src={logo} alt="logo" className="logo" maxHeight={90} />
        </Card.Content>
      )}
      <Card.Content className="information">
        <Card.Header> {name}</Card.Header>
        <Card.Description>
          { tags.map(t => <SponsorTag key={t.field} item={item} tag={t} />) }
        </Card.Description>
        <Card.Description>{shortDescription}</Card.Description>
      </Card.Content>
    </Card>
  );
};

SponsorCard.defaultProps = {};

SponsorCard.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    category: PropTypes.string,
    collection: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
  }).isRequired,
};

export default SponsorCard;
