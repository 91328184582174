import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useAutoRefresh } from '../hooks/useAutoRefresh';
// import store from '../shared/Store';

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const LINK_EXPIRATION_DELAY = 120 * MINUTES;
const startTime = Date.now();

function timeLeft() {
  return startTime + LINK_EXPIRATION_DELAY - Date.now();
}

function hasExpired() {
  // TODO: disabled if logged in ? Importing the store breaks storybook ??
  return /* store.hasSavedUser() && */ timeLeft() <= 0;
}

function isExternalLink(url: string) {
  return url && url.indexOf('http') === 0;
}

const ExpiringNavLink: FC<any> = (props: Record<string, any>): JSX.Element => {
  useAutoRefresh(Math.max(1 * MINUTES, timeLeft()));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { to, visibleBy, ...rest } = props;
  if (isExternalLink(to)) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...rest} href={to} target="_blank" rel="noreferrer" />;
  }

  const linkProps = { ...rest };
  if (hasExpired()) {
    linkProps.component = 'a';
  }
  return <NavLink to={to} {...linkProps} />;
};

ExpiringNavLink.propTypes = (NavLink as any).propTypes;

export default ExpiringNavLink;
