import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon } from 'semantic-ui-react';
import { speakerPropTypes } from '../../../../propTypes';
import SquareImage from '../../../SquareImage';
import { cardMetaComponents, SocialNetworks } from '../components';
// eslint-disable-next-line import/no-cycle
import { EntityTags } from '../../../../workshops/components/WorkshopTable/WorkshopModal';
import EntityLink from '../../../EntityLink';
import { getString } from '../../../../utils';

const translationPrefix = 'speakers.card';

export const speakerThumbnailOptions = {
  type: 'auto', // Allow webp
};

const ClassicSpeakerCard = ({
  children,
  extraFields,
  fluid,
  imageSize,
  ligthVariant,
  showLink,
  showModal,
  showMailBubble,
  speaker,
  tagFields,
  width,
  ...rest
}) => {
  const { defaultThumbnail } = rest;
  const {
    company,
    shortDescription,
    firstName,
    lastName,
    role,
    thumbnail,
    email,
    socialNetworks,
    category,
  } = speaker;

  return (
    <>
      <Card
        className="card--speaker"
        style={{ width }}
        fluid={fluid}
        as={showLink || showModal ? EntityLink : undefined}
        entity={speaker}
        data-category={category || undefined}
      >
        {(thumbnail || defaultThumbnail) && (
          <div>
            <SquareImage
              as="img"
              alt={`${firstName} ${lastName}`}
              src={thumbnail || defaultThumbnail}
              imageSize={imageSize || (ligthVariant ? 110 : 128)}
              cdnOptions={speakerThumbnailOptions}
              className={`avatar ${ligthVariant ? 'light' : ''}`}
            />

            {showMailBubble && email && (
              <a className="email bubble" href={`mailto:${email}`} target="_blank" rel="noreferrer">
                <Icon name="envelope outline" />
              </a>
            )}
          </div>
        )}

        <Card.Content className="content">
          <Card.Header as="h2">
            {firstName} {lastName}
          </Card.Header>
          {company && <Card.Description>{company}</Card.Description>}
          {role && (
            <Card.Meta>
              <span className="date">{role} </span>
            </Card.Meta>
          )}
          {extraFields &&
            extraFields
              .filter((field) => speaker[field.dataKey])
              .map(({ lineType, dataKey, className, ...fieldProps }) => {
                const value = speaker[dataKey];
                const Component = cardMetaComponents[lineType] || Card.Meta;
                return (
                  <Component
                    key={dataKey}
                    className={`${className || ''} field-${dataKey}`}
                    {...fieldProps}
                  >
                    {value}
                  </Component>
                );
              })}
          {shortDescription && !ligthVariant && (
            <Card.Description>{shortDescription}</Card.Description>
          )}
          <EntityTags tagFields={tagFields} entity={speaker} type="speaker" />
          {socialNetworks && !ligthVariant && <SocialNetworks socialNetworks={socialNetworks} />}
        </Card.Content>
        {showLink && (
          <Card.Content className="navlink">
            <EntityLink autoHide entity={speaker}>
              {getString(`${translationPrefix}.view-sessions`)}
            </EntityLink>
          </Card.Content>
        )}
        {children}
      </Card>
    </>
  );
};

ClassicSpeakerCard.defaultProps = {
  children: undefined,
  extraFields: [],
  fluid: undefined,
  imageSize: undefined,
  modalConfig: {},
  ligthVariant: false,
  showLink: true,
  showMailBubble: false,
  showModal: false,
  tagFields: [],
  width: undefined,
};

ClassicSpeakerCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  extraFields: PropTypes.arrayOf(PropTypes.shape({ dataKey: PropTypes.string })),
  fluid: PropTypes.bool,
  imageSize: PropTypes.number,
  ligthVariant: PropTypes.bool,
  modalConfig: PropTypes.object,
  showLink: PropTypes.bool,
  showMailBubble: PropTypes.bool,
  showModal: PropTypes.bool,
  speaker: speakerPropTypes.isRequired,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.number,
};

export default ClassicSpeakerCard;
