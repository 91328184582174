import { useToggle } from 'ahooks';
import cx from 'classnames';
import { isBefore, parseISO } from 'date-fns';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import PropTypes from 'prop-types';
import { stringify } from 'querystring';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import BlockContainer from '../../components/BlockContainer';
import CdnImage from '../../components/CdnImage';
import VideoEmbed from '../../components/VideoEmbed';
import VideoSidebar from '../../components/VideoWithSidebar/components/VideoSidebar';
import { useScreenConfig } from '../../config/screens.context';
import { useTracking } from '../../Context';
import { bem } from '../../core/design/bem';
import { eventTags } from '../../core/trackers/events';
import VideoTracker from '../../core/trackers/VideoTracker';
import { useTimeLeft } from '../../home/blocks/CountdownBlock';
import { countdownVariants } from '../../home/blocks/NextLiveCountdownBlock/variants';
import { containerTypePropTypes, imageProptypes } from '../../propTypes';
import store from '../../shared/Store';
import { getString } from '../../utils';
import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import Poster from '../components/Poster';
import './WorkshopLiveBlock.scss';
import { useDisableFirebase } from '../../utils/useFirebase';

const translationPrefix = 'workshops.workshop';

const videoSizer = bem('VideoSizer');

export const sidebarProps = PropTypes.shape({
  alwaysOpen: PropTypes.bool,
  tabs: PropTypes.array,
});

function injectUserData(url, { disableFirebase }) {
  if (url.indexOf('userId') !== -1) {
    return url;
  }

  // Time to inject
  const { user } = store;
  const qs = stringify(
    omitBy(
      {
        userId: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        thumbnail: user.thumbnail?.uri,
        disableFirebase: disableFirebase || undefined,
      },
      isUndefined,
    ),
  );
  const glue = url.indexOf('?') === -1 ? '?' : '&';
  return url + glue + qs;
}

const LiveCountdown = ({
  className,
  containerType,
  header,
  backgroundImage,
  label,
  template,
  item,
  ...rest
}) => {
  const timeLeft = useTimeLeft(item?.startDate);
  const Component = (template && get(countdownVariants, template)) || countdownVariants.square;
  return (
    <BlockContainer
      className={cx(className, 'block--video', 'block--video--countdown')}
      type={containerType}
      header={header}
    >
      <CdnImage src={backgroundImage} maxWidth={1200} />
      <div className="block--video--countdown__container">
        <div className="countdown__label">{label}</div>
        <Component {...rest} timeLeft={timeLeft} type={template} {...rest} />
      </div>
    </BlockContainer>
  );
};

LiveCountdown.defaultProps = {
  className: undefined,
  containerType: undefined,
  header: undefined,
  backgroundImage: undefined,
  label: undefined,
  template: undefined,
  item: undefined,
};

LiveCountdown.propTypes = {
  className: PropTypes.string,
  containerType: PropTypes.string,
  header: PropTypes.string,
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  template: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.any,
};

const VideoSizer = ({ children, alwaysOpen }) => {
  return (
    <div className={videoSizer({ alwaysOpen }).toString()}>
      <div className={videoSizer('container').toString()}>{children}</div>
    </div>
  );
};

VideoSizer.defaultProps = {
  children: undefined,
  alwaysOpen: undefined,
};

VideoSizer.propTypes = {
  children: PropTypes.node,
  alwaysOpen: PropTypes.bool,
};

const defaultTabs = [
  { key: 'q&a', field: 'qaUri', title: 'Q&A', visible: true },
  { key: 'chat', field: 'chatUri', title: 'Chat', visible: true },
];

function useVideoTabs(video, tabs) {
  const disableFirebase = useDisableFirebase();
  return useMemo(() => {
    return (
      (tabs || defaultTabs)
        .filter((tab) => get(video, tab.field))
        // Handle closing with custom field and default state
        .filter((tab) =>
          tab.visibleField ? get(video, tab.visibleField, tab.visible) : tab.visible,
        )
        .map((tab) => ({
          ...tab,
          url: injectUserData(get(video, tab.field), { disableFirebase }),
        }))
    );
  }, [video, tabs, disableFirebase]);
}

const VideoWithQA = ({ allowFullScreen, videoProps, video, sidebar, onClickLive, item }) => {
  const [showTab, { toggle: toggleShowTab }] = useToggle(false);

  const videoEmbed = (
    <VideoEmbed
      icon="play"
      defaultActive
      allowFullScreen={allowFullScreen}
      iframe={{
        allowFullScreen,
      }}
      {...videoProps}
      {...video}
      url={video.uri}
      item={item}
      onClick={onClickLive}
    />
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { alwaysOpen, tabs = defaultTabs, tabsAlwaysVisible } = sidebar || {};
  const videoTabs = useVideoTabs(video, tabs);
  if (videoTabs.length === 0) {
    return videoEmbed;
  }

  return (
    <VideoSizer alwaysOpen={alwaysOpen}>
      <div
        className={videoSizer('video', { showTab: showTab && !alwaysOpen, alwaysOpen }).toString()}
      >
        {videoEmbed}
        {!alwaysOpen && (
          <Button
            className={videoSizer('icon').toString()}
            icon={showTab ? 'close' : 'comment'}
            size="huge"
            primary
            circular
            onClick={() => toggleShowTab()}
          />
        )}
      </div>
      <VideoSidebar
        open={showTab || alwaysOpen}
        tabsAlwaysVisible={tabsAlwaysVisible}
        tabs={videoTabs}
      />
    </VideoSizer>
  );
};

VideoWithQA.defaultProps = {
  allowFullScreen: undefined,
  videoProps: undefined,
};

VideoWithQA.propTypes = {
  allowFullScreen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  videoProps: PropTypes.any,
};
const WorkshopLiveBlock = ({
  allowFullScreen,
  className,
  containerType,
  field,
  item,
  registration,
  videoProps,
  replayField,
  title,
  countdown,
  sidebar,
  preLiveImage,
  postLiveImage,
  defaultActive,
}) => {
  const { mandatory } = item;
  const videos = item[field] || [];
  const { trackEvent } = useTracking();
  const { workshop = {} } = useScreenConfig('workshops');
  const { liveAvailableOnlyForRegistered = false, countdownMode = 'default' } = workshop;
  const now = useAutoRefresh(60000); // TODO: voir pour le passer à 5 minutes
  const startAt = parseISO(item.startDate);

  function onViewLive() {
    trackEvent(eventTags.WORKSHOP_VIEW_LIVE, {
      userId: store.userId,
      workshop: item,
    });
  }
  function onClickLive() {
    trackEvent(eventTags.WORKSHOP_CLICK_LIVE, {
      userId: store.userId,
      workshop: item,
    });
  }

  // Track on mount ?
  const autoHide = liveAvailableOnlyForRegistered && !registration && !mandatory;
  const hasVideos = !autoHide && videos && videos.length > 0;
  const hasReplays = item[replayField]?.length;
  useEffect(() => {
    if (hasVideos) {
      onViewLive();
    }
  }, [hasVideos, item._id]);

  if (autoHide) {
    return null;
  }

  if (!hasVideos && !hasReplays) {
    if (isBefore(now, startAt) && countdown?.backgroundImage && countdown?.label) {
      return (
        <LiveCountdown
          containerType={containerType}
          item={item}
          header={title || getString(`${translationPrefix}.live-streaming`)}
          className={className}
          {...countdown}
        />
      );
    }
    return (
      <Poster
        countdownMode={countdownMode}
        item={item}
        header={title || getString(`${translationPrefix}.live-streaming`)}
        className="block--video"
        containerType={containerType}
        postLiveImage={item.postLiveImage?.image ? item.postLiveImage : postLiveImage}
        preLiveImage={item.preLiveImage?.image ? item.preLiveImage : preLiveImage}
      />
    );
  }
  if (!hasVideos) return null;

  const video = videos[0];
  return (
    <BlockContainer
      className={cx(className, 'block--video')}
      type={containerType}
      header={title || getString(`${translationPrefix}.live-streaming`)}
    >
      <VideoTracker item={item} video={video} videoType="live" />
      <VideoWithQA
        item={item}
        defaultActive={defaultActive}
        allowFullScreen={allowFullScreen}
        sidebar={sidebar}
        videoProps={videoProps}
        video={video}
        onClickLive={onClickLive}
      />
    </BlockContainer>
  );
};

WorkshopLiveBlock.defaultProps = {
  allowFullScreen: true,
  className: undefined,
  containerType: 'segment',
  countdown: undefined,
  field: 'liveStreams',
  replayField: 'replays',
  title: undefined,
  registration: undefined,
  videoProps: {},
  preLiveImage: undefined,
  postLiveImage: undefined,
  sidebar: undefined,
};

WorkshopLiveBlock.propTypes = {
  allowFullScreen: PropTypes.bool,
  className: PropTypes.string,
  containerType: containerTypePropTypes,
  countdown: PropTypes.shape({
    backgroundImage: imageProptypes,
    label: PropTypes.string,
  }),
  item: PropTypes.object.isRequired,
  field: PropTypes.string,
  registration: PropTypes.object,
  replayField: PropTypes.string,
  title: PropTypes.string,
  preLiveImage: PropTypes.object,
  postLiveImage: PropTypes.object,
  sidebar: sidebarProps,
  videoProps: PropTypes.shape({
    defaultActive: PropTypes.bool,
  }),
};

const ConnectedWorkshopLiveBlock = connect((state, props) => ({
  registrations: state.registrations,
  registration: state.registrations.registrationsById[props.item._id],
}))(WorkshopLiveBlock);

export default ConnectedWorkshopLiveBlock;
