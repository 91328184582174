/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import BackButton from '../components/BackButton';
import CdnImage from '../components/CdnImage';
import Footer from '../components/Footer';
import PageBackground from '../components/PageBackground';
import { useConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import { useIsPreview } from '../config/preview.context';
import { bem } from '../core/design/bem';
import PageMeta from '../core/metadata/PageMeta';
// eslint-disable-next-line import/no-cycle
import { withScreenClosed } from '../core/screens/screens.hoc';
import { HorizontalMenu } from '../Layouts/Default/components/HorizontalMenu';
import VerticalMenu from '../Layouts/Default/components/VerticalMenu';
import VerticalMenuHeader from '../Layouts/Default/components/VerticalMenu/components/VerticalMenuHeader';
import { imageProptypes } from '../propTypes';
import Styles from '../Styles';
import './CmsPage.scss';
import { CmsBlocks } from './CmsScreen';

const pageClassName = bem('page');

const menuContainer = bem('menu-container');

const headerProps = {
  backgroundColor: PropTypes.string,
  logo: imageProptypes,
};

const CmsPageHeader = ({ logo, backgroundColor }) => {
  return (
    <div className="header--fixed" style={{ backgroundColor }}>
      <div className="container">
        {logo && <CdnImage className="logo" maxHeight={100} src={logo} as="img" />}
      </div>
    </div>
  );
};

CmsPageHeader.defaultProps = {
  logo: undefined,
  backgroundColor: undefined,
};
CmsPageHeader.propTypes = headerProps;

export const CmsPageLayout = ({
  className,
  header,
  cmsHeader,
  metadata,
  item,
  pageFooter,
  pageHeader,
  designOverride,
  children,
}) => {
  const design = useDesignConfig();
  const { menu } = useConfig();
  const hasHeader = !!header;
  const background = designOverride.background || design.background; // page design
  const mobileMenu = design?.menu?.mobile || {};
  const { color: backgroundColor, image: backgroundImage } = background || {};
  const isPreview = useIsPreview();
  const isVerticalMenu = design?.menu?.wrapper?.variant === 'leftSidebar';

  return (
    <div
      className={cx(className ? className.toString() : undefined, {
        'headered': hasHeader,
        'page--verticalMenu': isVerticalMenu,
      })}
      css={css(designOverride?.css, designOverride?.cssStyle)}
    >
      {!isEmpty(designOverride) && <Styles designOverride={designOverride} />}
      {isVerticalMenu && menu && <VerticalMenu menu={menu} />}
      <PageMeta {...metadata} item={item} />
      <PageBackground
        {...background}
        image={backgroundImage || (!backgroundColor && design.homeBackground)}
      />
      {header && <CmsPageHeader {...header} />}
      <div className="page--cms-container">
        {!isVerticalMenu && !isPreview && (
          <div className={menuContainer({ 'fixed-mobile': mobileMenu.fixed }).toString()}>
            {menu && <HorizontalMenu menu={menu} />}
          </div>
        )}
        {/** Deprecated => use instead pageHeader */}
        {cmsHeader && <CmsBlocks blocks={cmsHeader?.blocks} />}
        {pageHeader && <CmsBlocks blocks={pageHeader?.blocks} />}
        <div className="content">
          {!isPreview && (
            <div className="BackButton__container">
              <BackButton />
            </div>
          )}
          {isVerticalMenu && !isPreview && <VerticalMenuHeader design={design} />}
          {children}
        </div>
        {pageFooter && (
          <div className="page--cms-footer">
            <CmsBlocks blocks={pageFooter?.blocks} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

CmsPageLayout.defaultProps = {
  className: '',
  cmsHeader: undefined,
  header: undefined,
  designOverride: {},
  item: undefined,
  metadata: undefined,
  pageFooter: undefined,
  pageHeader: undefined,
};

CmsPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cmsHeader: PropTypes.object,
  designOverride: PropTypes.object,
  header: PropTypes.object,
  item: PropTypes.object,
  metadata: PropTypes.object,
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
};

function CmsPage(props) {
  const { blocks, header, metadata, design, id, pageId, pageHeader, pageFooter, ...rest } = props;
  return (
    <CmsPageLayout
      className={pageClassName({
        [pageId]: true,
        cms: true,
      })
        .mix([id, pageId]) // Legacy format
        .toString()}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <CmsBlocks blocks={blocks} {...rest} />
      </Container>
    </CmsPageLayout>
  );
}

CmsPage.defaultProps = {
  blocks: [],
  design: {},
  header: {},
  id: '',
  item: undefined,
  metadata: undefined,
  pageFooter: undefined,
  pageHeader: undefined,
};

CmsPage.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  design: PropTypes.object,
  header: PropTypes.object,
  id: PropTypes.string,
  item: PropTypes.object,
  metadata: PropTypes.object,
  pageFooter: PropTypes.object,
  pageId: PropTypes.string.isRequired,
  pageHeader: PropTypes.object,
};

export default withScreenClosed(CmsPage, { className: 'page--cms' });
