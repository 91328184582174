/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import { Card, Icon, Image } from 'semantic-ui-react';
import { ImageWithAspectRatio } from '../components/CdnImage';
import { avatarWithFallbackWhenMissing } from '../utils/avatarUtils';
import { useScreenConfig } from '../config/screens.context';
import SocialWallReaction from './SocialWallReaction';
import { sweetConfirmAlert } from '../utils/popupUtils';
import { getString } from '../utils';
import NewSocialWallPostModal from './NewSocialWallPostModal';
import socialWallService from '../core/services/social-wall.service';
import { fromNow } from '../utils/dateUtils';
import { useMe } from '../profile/hooks';

const translationPrefix = 'social-wall';

export const defaultReactions = [
  { key: 'love', icon: 'heart', color: 'red' },
  { key: 'like', icon: 'thumbs up', color: 'blue' },
  { key: 'happy', icon: 'smile', color: 'yellow' },
];

const Message = ({ message, hasImage }) => {
  if (!message) return null;
  return (
    <>
      {!hasImage ? (
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <Icon name="quote left" />
          <Card.Description className="SocialWall--Card__Message quoted">
            {message}
          </Card.Description>
          <Icon name="quote right" />
        </div>
      ) : (
        <Card.Description className="SocialWall--Card__Message">{message}</Card.Description>
      )}
    </>
  );
};

Message.defaultProps = {
  hasImage: undefined,
  message: undefined,
};

Message.propTypes = {
  message: PropTypes.string,
  hasImage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const SocialWallMessageInfo = ({ thumbnail, firstName, lastName, createdAt }) => (
  <div className="SocialWall--Card__User">
    <Image
      className="thumbnail"
      src={avatarWithFallbackWhenMissing(thumbnail)}
      avatar
      size="mini"
    />
    <div>
      <Card.Description className="fullname">
        {firstName} {lastName}
      </Card.Description>
      <Card.Description className="date">
        <Icon name="clock outline" />
        {fromNow(createdAt)}
      </Card.Description>
    </div>
  </div>
);

function SocialWallCard(props) {
  const { post, likes, onClick } = props;
  const [isOpen, setIsOpen] = useState(false);
  const me = useMe();
  const { enableEdition = false, reactions = defaultReactions } = useScreenConfig('socialWall');
  const { id: messageId, image, location, message, userId } = post;
  const isMyPost = userId === me?._id;
  const handleDelete = async () => {
    const confirm = await sweetConfirmAlert({
      title: getString(`${translationPrefix}.delete-post`),
      confirmButtonText: getString(`alert.confirm`),
      cancelButtonText: getString(`alert.cancel`),
    });
    if (confirm) {
      await socialWallService.deleteMessage(messageId);
    }
  };
  return (
    <div className="SocialWall--Card__Container">
      <ImageWithAspectRatio
        src={image}
        maxWidth={400}
        fluid
        className="SocialWall--Card__Image"
        onClick={() => onClick(messageId)}
      />
      <div style={{ padding: 12 }}>
        {location && (
          <p>
            <Icon name="map marker alternate" /> {location}
          </p>
        )}
        <Message message={message} hasImage={!!image} />
        <SocialWallMessageInfo {...post} />
        <div className="SocialWall--Card__Actions">
          <hr />
          <div style={{ display: 'flex' }}>
            <div className="SocialWall--Card__Actions--reactions">
              {reactions.map((reaction) => (
                <SocialWallReaction key={reaction.key} item={reaction} post={post} likes={likes} />
              ))}
            </div>
            {isMyPost && enableEdition && (
              <div className="SocialWall--Card__Actions--edition">
                <Icon name="pencil" color="blue" onClick={() => setIsOpen(true)} />
                <Icon name="trash" color="red" onClick={handleDelete} />
              </div>
            )}
          </div>
          {isOpen && <NewSocialWallPostModal post={post} onClose={() => setIsOpen(false)} />}
        </div>
      </div>
    </div>
  );
}

SocialWallCard.defaultProps = {
  onClick: undefined,
};

SocialWallCard.propTypes = {
  likes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
    firstName: PropTypes.string,
    image: PropTypes.object,
    lastName: PropTypes.string,
    location: PropTypes.string,
    message: PropTypes.string,
    rest: PropTypes.object,
    thumbnail: PropTypes.object,
    userId: PropTypes.string.isRequired,
  }).isRequired,
};

export default SocialWallCard;
